/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof OrderProductSummary} Color Swatch class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory order
     * @class OrderProductSummary
     * @augments Widget
     * @classdesc Represents ordered products summary with next features:
     * 1. Handles click on product link
     *
     * @example <caption>Order products summary</caption>
     * <div data-widget="orderProductSummary">
     *     <isloop items="${pdict.order.shipping}" var="shippingModel">
     *         <isloop items="${shippingModel.productLineItems.items}" var="lineItem">
     *             <isif condition="${lineItem.isBonusProductLineItem}">
     *                 <isinclude template="checkout/productCard/bonusProductCard" />
     *             <iselse/>
     *                 <isinclude template="checkout/productCard/productCard" />
     *             </isif>
     *         </isloop>
     *         <isif condition="${pdict.order.shipping.length > 1}">
     *             <isinclude template="checkout/shipping/shippingCard" />
     *         </isif>
     *     </isloop>
     * </div>
     */
    class OrderProductSummary extends Widget {
        /**
         * @description Executes when user clicks on order product link.
         * Usually used by analytics etc.
         * @emits "orderdetail.product.link.click"
         * @param {refElement} link - clicked product tile link
         * @returns {void}
         */
        onProductLinkClick(link) {
            this.eventBus().emit('orderdetail.product.link.click', link);
        }
    }

    return OrderProductSummary;
}
