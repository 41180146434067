import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/ConfirmDialog').default>>} confirmDialog
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof AddressList} AddressList widget
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory account
     * @class AddressList
     * @augments Widget
     * @classdesc Represents AddressList component with next features:
     * 1. Allow remove address
     * 2. Update address tile after removal
     *
     * Widget has next relationship:
     * *Use {@link ConfirmDialog#showModal} method to show confirmation dialog when remove address button click
     * @property {string} data-widget - Widget name `addressList`
     * @property {object} data-accessibility-alerts - Accessibility alerts messages for different user actions
     * @example <caption>Example of AddressList widget usage</caption>
     *  <div
     *      class="b-address_book"
     *      data-widget="addressList"
     *      data-tau="address_book_list"
     *      data-accessibility-alerts='{
     *          "deleteDefaultAddress": "${Resource.msg('alert.deleteDefaultAddress', 'addressBook', null)}",
     *          "addressRemoved": "${Resource.msg('alert.addressRemoved', 'addressBook', null)}"
     *      }'
     *  >
     *      <!-- Rows for addresses in the Address Book -->
     *      <isloop items="${pdict.addressBook}" var="address">
     *          <section
     *              class="b-address_book-item ${address.isDefault ? 'm-default' : ''}"
     *              id="uuid-${address.addressUUID}"
     *              data-ref="${address.ID}"
     *              data-is-default-address="${address.isDefault}"
     *              <isif condition="${address.isDefault}">
     *                  data-tau="address_book_item_default"
     *              <iselse/>
     *                  data-tau="address_book_item"
     *              </isif>
     *          >
     *              <div class="b-address_book-header">
     *                  <isif condition="${address.isDefault}">
     *                      <h2 class="b-address_book-title">
     *                          ${Resource.msg('label.addressbook.defaultaddress','account',null)}
     *                      </h2>
     *                  <iselse/>
     *                      <a
     *                          href="${URLUtils.url('Address-SetDefault', 'addressId', address.ID)}"
     *                          class="b-address_book-link b-address_book-set_default"
     *                          data-tau="address_book_setDefault"
     *                          aria-label="${Resource.msg('addressBook.makeDefault.wai','addressBook',null)}."
     *                      >
     *                          ${Resource.msg('addressBook.makeDefault','addressBook',null)}
     *                      </a>
     *                  </isif>
     *                  <a
     *                      href="${URLUtils.url('Address-EditAddress', 'addressId', address.ID)}"
     *                      class="b-address_book-link b-address_book-edit"
     *                      data-tau="address_book_edit"
     *                  >
     *                      ${Resource.msg('common.edit','common',null)}
     *                  </a>
     *                  <button
     *                      type="button"
     *                      class="b-address_book-link b-address_book-delete"
     *                      data-toggle="modal"
     *                      data-target="#deleteAddressModal"
     *                      data-tau="address_book_delete"
     *                      data-id="${address.ID}"
     *                      data-url="${pdict.actionUrls.deleteActionUrl}"
     *                      data-event-click="removeAddress"
     *                      aria-label="${Resource.msg('addressBook.delete.wai','addressBook',null)}"
     *                      data-default="${address.isDefault ? 'default' : '' }"
     *                  >
     *                      ${Resource.msg('common.delete','common',null)}
     *                  </button>
     *              </div>
     *              <div class="b-address_book-body m-delete_card">
     *                  <div
     *                      class="b-address_book-info"
     *                      data-tau="address_book-info"
     *                      id="address-details-${address.addressUUID}"
     *                  >
     *                      <isprint value="${address.rendered}" encoding="off" />
     *                  </div>

     *                  <isinclude template="account/deleteAddressModal"/>
     *              </div>
     *          </section>
     *      </isloop>
     *      <div data-ref="addressTile"></div>
     *      <script type="template/mustache" data-ref="addressTile">
     *          <isinclude template="m/account/addressBook/address"/>
     *      </script>
     *  </div>
     */
    class AddressList extends Widget {
        prefs() {
            return {
                accessibilityAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description Show confirmation dialog on remove address button click
         * @listens dom#click
         * @param {RefElement} address removed address tile
         * @returns {void}
         */
        removeAddress(address) {
            this.addressToRemove = address;
            this.getById('confirmDialog', (/** @type {confirmDialog} */confirmDialog) => {
                confirmDialog.showModal({ addressTitle: address.data('id') });
            });
        }

        /**
         * @description Remove address
         * @listens dom#click
         * @emits "alert.show"
         * @returns {void}
         */
        confirmedRemoveAddress() {
            if (!this.addressToRemove) {
                return;
            }
            const addressId = this.addressToRemove.data('id');
            const isDefault = this.addressToRemove.data('default');
            getJSONByUrl(this.addressToRemove.data('url'), {
                addressId,
                isDefault
            }).then((response) => {
                const addressCard = this.ref(addressId);
                const { defaultAddress, urls } = response;
                const addNewAddressElement = this.ref('addNewAddress').get();
                if (addressCard) {
                    addressCard.remove();
                    delete this.addressToRemove;
                    let accessibilityAlert = this.prefs().accessibilityAlerts.addressRemoved;
                    if (addressCard.data('isDefaultAddress')) {
                        if (defaultAddress) {
                            this.updateAddressTile(defaultAddress.address.addressId, {
                                isDefault: true,
                                address: defaultAddress,
                                urls
                            });
                        }
                        accessibilityAlert = this.prefs().accessibilityAlerts.deleteDefaultAddress;
                    }
                    if (addNewAddressElement) {
                        addNewAddressElement.focus();
                    }
                    /**
                     * @description Global event to show alert
                     * @event "alert.show"
                     */
                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }
            });
        }

        /**
         * @description Updates address tile
         * @param {object} addressID - target address ID
         * @param {object} templateParams - rendering template params
         * @returns {void}
         */
        updateAddressTile(addressID, templateParams) {
            this.render('addressTile', templateParams, this.ref(addressID));
        }
    }

    return AddressList;
}
