/**
 * @param {InputCreditCardNumber} InputCreditCardNumber Base widget for extending
 * @returns {typeof HarmonyInputCreditCardNumber}
 */
export default function (InputCreditCardNumber) {
    return class HarmonyInputCreditCardNumber extends InputCreditCardNumber {
        /**
         * @description Triggers input validation process
         * @returns {boolean} input validation result
         */
        validate() {
            // Need to dynamically update CVV validation rules based on credit card type
            this.eventBus().emit('process.card.number', this.getValue());

            return super.validate();
        }
    };
}
