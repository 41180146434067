/**
 * @typedef {ReturnType<typeof import('widgets/forms/AddressBookForm').default>} AddressForm
 */

export default function (AddressBookForm) {
    class HarmonyAddressBookForm extends AddressBookForm {
        /**
         * @description Check if default address was changed and triggered Global Alerts
         * @emits "alert.show"
         * @param {object} response Server JSON response once form submitted
         * @returns {void}
         */
        triggerEditAddressEvent(response) {
            const isCurrentAddressDefault = this.prefs().isDefaultAddress;
            const isSavedAddressDefault = response.setAsDefault;

            let accessibilityAlert = this.prefs().accessibilityAlerts.addressChanged;

            if (isCurrentAddressDefault && !isSavedAddressDefault && !response.isNonCompliant) {
                accessibilityAlert = this.prefs().accessibilityAlerts.unselectedDefaultAddress;
            } else if (!isCurrentAddressDefault && isSavedAddressDefault && !response.isNonCompliant) {
                accessibilityAlert = this.prefs().accessibilityAlerts.defaultAddressChanged;
            }

            /**
             * @description Global event to show alert
             * @event "alert.show"
             */
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });
        }
    }

    return HarmonyAddressBookForm;
}
