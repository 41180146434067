import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/ConfirmDialog').default>>} confirmDialog
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof PaymentsList} PaymentsList widget
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory account
     * @class PaymentsList
     * @augments Widget
     * @classdesc Represents PaymentsList component with next features:
     * 1. PaymentsList component used on Payment Methods List in My Account. It implements removing Payment Instruments.
     *
     * Widget has next relationship:
     * *Use {@link ConfirmDialog#showModal} method to show confirmation dialog when remove card button click
     * @property {string} data-widget - Widget name `paymentsList`
     * @example <caption>Example of PaymentsList widget usage</caption>
     * <div
     *      data-widget="paymentsList"
     *  >
     *      Payment content....
     *          <div class="b-dialog-footer m-actions">
     *              <button
     *                  data-widget-event-click="removeCard"
     *              >
     *                  Remove card button
     *              </button>
     *          </div>
     *  </div>
     */
    class PaymentsList extends Widget {
        /**
         * @description Show confirmation dialog on remove card button click
         * @listens dom#click
         * @param {RefElement} card removed payment card
         * @returns {void}
         */
        removeCard(card) {
            this.cardToRemove = card;
            this.getById('confirmDialog', (/** @type {confirmDialog} */confirmDialog) => {
                confirmDialog.showModal({ cardNumber: card.data('card') });
            });
        }

        /**
         * @description Remove card
         * @listens dom#click
         * @returns {void}
         */
        confirmedRemoveCard() {
            if (this.cardToRemove) {
                getJSONByUrl(this.cardToRemove.data('url'), { UUID: this.cardToRemove.data('id') }).then(() => {
                    this.ref('self').remove();
                    delete this.cardToRemove;
                });
            }
        }
    }

    return PaymentsList;
}
