import { getJSONByUrl, getContentByUrl } from 'widgets/toolbox/ajax';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {ReturnType<typeof import('widgets/forms/InputSelect').default>} InputSelect
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof OrderHistoryMgr} OrderHistoryMgr widget
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory account
     * @class OrderHistoryMgr
     * @augments Widget
     * @classdesc Represents OrderHistoryMgr component with next features:
     * 1. Allow load more order
     * 2. Allow update order list by order create  date using select options(last 6 months/ last 12 months)
     * 3. Allow show loader overlay while updating results
     * @property {string} data-widget - Widget name `OrderHistoryMgr`
     * @property {object} data-accessibility-alert - Accessibility alerts messages for different user actions
     * @example <caption>Example of OrderHistoryMgr widget usage</caption>
     * <isdecorate template="common/layout/account">
     *      <h1 class="b-account-history_title">
     *          ${Resource.msg('history.title','history',null)}
     *      </h1>
     *      <div data-widget="orderHistoryMgr"
     *          data-accessibility-alerts='{
     *              "orderlistupdated": "${Resource.msg('alert.orderlistupdated', 'order', null)}"
     *          }'
     *      >
     *          <isinclude template="account/order/historyOrders"/>
     *      </div>
     *  </isdecorate>
     */
    class OrderHistoryMgr extends Widget {
        prefs() {
            return {
                classesBusy: 'm-busy',
                accessibilityAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description Load more orders
         * @listens dom#click
         * @emits "alert.show"
         * @param {refElement} button Target element
         * @returns {void}
        */
        loadMore(button) {
            const href = String(button.attr('data-url'));

            this.busy();
            getJSONByUrl(href).then(response => {
                Promise.all([
                    this.ref('listFooter').remove(),
                    this.ref('orderList').append(response.orderListItems),
                    this.ref('orderList').append(response.orderListFooter)
                ]).then(()=> {
                    const accessibilityAlert = this.prefs().accessibilityAlerts.orderlistupdated;
                    /**
                     * @description Global event to show alert
                     * @event "alert.show"
                     */
                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }).finally(() => {
                    this.unbusy();
                });
            });
        }

        /**
         * @description Update Order List
         * @listens dom#click
         * @emits "alert.show"
         * @param {InstanceType<InputSelect>} select Target element
         * @returns {void}
         */
        updateOrderList(select) {
            const selectedSorting = select.getSelectedOptions();
            if (selectedSorting) {
                const url = String(selectedSorting.val());

                this.busy();
                getContentByUrl(url).then(response => {
                    if (typeof response === 'string') {
                        this.render(undefined, undefined, this.ref('grid'), response);
                    }
                }).then(()=> {
                    const accessibilityAlert = this.prefs().accessibilityAlerts.orderlistupdated;
                    /**
                     * @description Global event to show alert
                     * @event "alert.show"
                     */
                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }).finally(() => {
                    this.unbusy();
                });
            }
        }

        /**
         * @description Busy
         * @returns {void}
         */
        busy() {
            this.ref('grid').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
        }

        /**
         * @description Unbusy
         * @returns {void}
         */
        unbusy() {
            this.ref('grid').removeClass(this.prefs().classesBusy).attr('aria-busy', false);
        }
    }

    return OrderHistoryMgr;
}
