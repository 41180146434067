/**
 * @typedef {ReturnType<typeof import('widgets/forms/AddressForm').default>} AddressForm
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/BasicInput').default>>} BasicInput
 */

/**
 * @param {AddressForm} AddressForm Base widget for extending
 * @returns {typeof AddressBookForm} Address Form class
 */
export default function (AddressForm) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AddressBookForm
     * @augments AddressForm
     * @classdesc Represents AddressBookForm component with next features:
     * 1. Show accessibility alert on address edit depends on the address was the default or not
     * 2. Allow handle server response
     * @property {string} data-widget - Widget name `addressBookForm`
     * @property {string} data-form-definition-url - Update form definition URL
     * @property {object} data-accessibility-alerts - Accessibility alerts messages
     * @example <caption>Example of AddressBookForm widget usage</caption>
     * <form
     *     data-widget="addressBookForm"
     *     data-event-submit.prevent="handleSubmit" novalidate
     *     action="${URLUtils.url('Address-SaveAddress')}" method="POST
     *     data-form-definition-url="${URLUtils.url('Forms-FormDefinition', 'form', 'address')}"
     * >
     *     <div class="alert alert-danger" hidden="hidden" data-ref="errorMessageLabel"></div>
     *     ... form fields
     *     <input type="hidden" name="${pdict.csrf.tokenName}" value="${pdict.csrf.token}"/>
     *     <div>
     *         <button
     *              type="submit"
     *              name="save"
     *              data-widget="button"
     *              data-widget-event-click="handleSubmit"
     *              data-event-click.prevent="handleClick"
     *              data-id="submitButton"
     *         >
     *              ${Resource.msg('button.save','account',null)}
     *         </button>
     *     </div>
     * </form>
     */
    class AddressBookForm extends AddressForm {
        prefs() {
            return {
                isDefaultAddress: false,
                accessibilityAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description Check if default address was changed and triggered Global Alerts
         * @emits "alert.show"
         * @param {object} response Server JSON response once form submitted
         * @returns {void}
         */
        triggerEditAddressEvent(response) {
            let accessibilityAlert = {};
            const isEditedAddressDefault = this.prefs().isDefaultAddress;
            const isSavedAddressDefault = response.isDefault;
            if (isSavedAddressDefault && isEditedAddressDefault) {
                accessibilityAlert = this.prefs().accessibilityAlerts.defaultAddressChanged;
            } else {
                accessibilityAlert = this.prefs().accessibilityAlerts.unseletedDefaultAddress;
            }
            /**
             * @description Global event to show alert
             * @event "alert.show"
             */
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });
        }

        /**
         * @description Check if setAsDefault value was changed and triggered Global Alerts
         * @param {object} data Server JSON response once form submitted
         * @returns {void}
         */
        onSubmitted(data) {
            if (data.success) {
                this.triggerEditAddressEvent(data);
            }
            super.onSubmitted(data);
        }
    }

    return AddressBookForm;
}
